/*
 * Copyright © Ergonode Sp. z o.o. All rights reserved.
 * See LICENSE for license details.
 */
export default () => ({
    user: null,
    token: null,
    refreshToken: null,
    isLogged: false,
});
